<template>
  <div class="list">
    <div class="article_form">
      <div class="article_form_title">考勤</div>
      <div class="article_jz">
        <div class="article_table">
          <div class="article_tr">
            <div class="article_td_right">现在时间：</div>
            <div class="article_td_left"><strong>{{nowDate}}</strong></div>
          </div>
          <div class="article_tr">
            <div class="article_td_right">考勤类型：</div>
            <div class="article_td_left">{{kaoqinguize.clockInRules==1?'定点制':'工时制'}}</div>
          </div>
          <div class="article_tr">
            <div class="article_td_right">考勤规则：</div>
            <div class="article_td_left" v-if="kaoqinguize.clockInRules==1">{{getStartTime}}-{{getEndtTime}}</div>
            <div class="article_td_left" v-else-if="kaoqinguize.clockInRules==2">{{kaoqinguize.workingHours}}(小时)</div>
            <div class="article_td_left" v-else>{{kaoqinguize.workingHours}}</div>
          </div>
          <div class="article_tr">
            <div class="article_td_right">选择员工：</div>
            <div class="article_td_left">
              <select v-model="clockInOBJ.userId" @change="addXingming">
                <option :value="item.id" v-for="item in EmpList" :key="item.id">{{item.xingming}}</option>
              </select>
            </div>
          </div>
          <div class="article_tr">
            <div class="article_td_center"><button class="settab" @click="clockInNow">打卡</button></div>
          </div>
          <div class="article_tr" style="font-size:14px; color:#d06707">
            <div class="article_td_right">注释：</div>
            <div class="article_td_left">电脑端无法定位，所以电脑端打卡会显示特定《电脑》字符</div>
          </div>
        </div>
      </div>
      <div class="clock_ul" v-if="dataList.length">
        <div class="clock_li" v-for="item in dataList" :key="item.userId">
          <div class="clock_li_a">{{item.xingming}}</div>
          <div class="clock_li_b">
            <div class="clock_li_b_1">上班</div>
            <div class="clock_li_b_1">下班</div>
          </div>
          <div class="clock_li_c">
            <div class="clock_li_b_1">{{item.shangban.guize}}</div>
            <div class="clock_li_b_1">{{item.xiaban.guize}}</div>
          </div>
          <div class="clock_li_c">
            <div class="clock_li_b_1">{{getDateTime(item.shangban.nowDate)}}</div>
            <div class="clock_li_b_1">{{getDateTime(item.xiaban.nowDate)}}</div>
          </div>
          <div class="clock_li_d">
            <div class="clock_li_b_1"><div class="clockStateTag" :class="getClass(state)" v-for="(state,i) in getClockState(item.shangban.clockState)" :key="i">{{state}}</div></div>
            <div class="clock_li_b_1"><div class="clockStateTag" :class="getClass(state)" v-for="(state,i) in getClockState(item.xiaban.clockState)" :key="i">{{state}}</div></div>
          </div>
          <div class="clock_li_d">
            <div class="clock_li_b_1">{{item.shangban.clockStateInfo}}</div>
            <div class="clock_li_b_1">{{item.xiaban.clockStateInfo}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from '@/http/axios'
import moment from 'moment'
import {testStr,deepCopy} from '@/utils/util'
export default {
  name: 'clockIn',
  data() {
    return {
      fenpeimendianId:this.$store.state.User.fenpeimendianId,
      EmpList:[],
      nowDate:moment().format("YYYY-MM-DD HH:mm:ss"),
      interval:false,
      kaoqinguize:{
        clockInRules:1,
        startTime:moment(),
        endTime:moment(),
        workingHours:0,
      },
      clockInOBJ:{
        fenpeimendianId:this.$store.state.User.fenpeimendianId,
        userId:null,
        clockState:['电脑'],
        clockStateInfo:'',
			},
      dataList:[],
    }
  },
  created() {
    this.getData()
    this.getEmpList()
    this.getKQJLNow()
  },
  computed:{
    getStartTime(){
      return this.kaoqinguize.startTime.format("HH:mm")
    },
    getEndtTime(){
      return this.kaoqinguize.endTime.format("HH:mm")
    },
  },
  components: {},
  mounted() {},
  methods: {
    getClass(str){
      if(str=="迟到"||str=="早退") return 'yichang'
      else return ''
    },
    getClockState(str){
      if(str) return str.split(',')
      else return []
    },
    addXingming(){
      let user = this.EmpList.find(e=>e.id==this.clockInOBJ.userId)
      if(user) this.clockInOBJ.xingming=user.xingming
    },
    getDateTime(sr){
      if(sr) return moment(sr).format("YYYY-MM-DD HH:mm:ss")
      else return ''
    },
    returnLBClass(lb){
      if(lb==1){
        return 'suc'
      }
      else{
        return 'err'
      }
    },
    countData(arr){
      let newArr=arr.filter((e,i)=>{
        return arr.findIndex(a=>a.userId==e.userId)==i
      })
      return newArr.map(e=>{
        return{
          userId:e.userId,
          xingming:e.xingming,
          shangban:arr.find(a=>a.userId==e.userId&&a.wokeing==1)||{},
          xiaban:arr.find(a=>a.userId==e.userId&&a.wokeing==2)||{},
        }
      })
    },
    getKQJLNow(){
      axios.post('/kaoqin/JLlist',{fenpeimendianId:this.fenpeimendianId}).then((res) => {
        if(res.code===5000){
          this.dataList=this.countData(res.data)
        }else this.$Mesg({content: res.msg,errOrSuc: 'err'})
      })
    },
    countWokeing(obj){
      if(obj){
        let {shangban,xiaban}=obj
        if(shangban.id&&xiaban.id){
          this.clockInOBJ.wokeing=2
          this.clockInOBJ.id=xiaban.id
        }else if(shangban.id){
          this.clockInOBJ.wokeing=2
          this.clockInOBJ.id=null
        }else{
          this.clockInOBJ.wokeing=1
          this.clockInOBJ.id=null
        }
      }else{
        this.clockInOBJ.wokeing=1
        this.clockInOBJ.id=null
      }
      
    },
    onInterval(){
      if(this.interval) window.clearInterval(this.interval)
      this.interval=window.setInterval(()=>{
        this.nowDate=moment(this.nowDate).add(1,"s").format("YYYY-MM-DD HH:mm:ss")
      },1000)
    },
    resDataInfo(arr){
      let one =arr.find(e=>e.fenpeimendianId!=0)
      let tow = arr.find(e=>e.fenpeimendianId==0)
      if(one){
        if(one.clockInRules==1){
          let startTime=moment(this.nowDate),endTime=moment(this.nowDate)
          startTime.hour(one.AMStartH)
          startTime.minute(one.AMStartM)
          startTime.second(0)
          endTime.hour(one.PMEndH)
          endTime.minute(one.PMEndM)
          endTime.second(0)
          this.kaoqinguize={clockInRules:1,startTime,endTime}
          this.clockInOBJ.guize=startTime.format("HH:mm")+"-"+endTime.format("HH:mm")
        }else{
          this.kaoqinguize={clockInRules:2,workingHours:one.workingHours}
          this.clockInOBJ.guize=one.workingHours+"(小时)"
        }
      }else if(tow){
        if(tow.clockInRules==1){
          let startTime=moment(),endTime=moment()
          startTime.hour(tow.AMStartH)
          startTime.minute(tow.AMStartM)
          startTime.second(0)
          endTime.hour(tow.PMEndH)
          endTime.minute(tow.PMEndM)
          endTime.second(0)
          this.kaoqinguize={clockInRules:1,startTime,endTime}
          this.clockInOBJ.guize=startTime.format("HH:mm")+"-"+endTime.format("HH:mm")
        }else{
          this.kaoqinguize={clockInRules:2,workingHours:tow.workingHours}
          this.clockInOBJ.guize=tow.workingHours+"(小时)"
        }
        
      }else{
        this.kaoqinguize={clockInRules:3,workingHours:'无规则'}
      }
    },
    getData(){
      axios.post('/kaoqin/getKQByMendianId',{fenpeimendianId:this.fenpeimendianId}).then((res) => {
        if(res.code===5000){
          this.nowDate=res.nowDate
          this.resDataInfo(res.data)
          this.onInterval()
        }else this.$Mesg({content: res.msg,errOrSuc: 'err'})
      })
    },
    getEmpList(){
      axios.post('/emp/list',{fenpeimendianId:this.fenpeimendianId}).then((res) => {
        if(res.code===5000){
          this.EmpList=res.data.map(e=>{
            return{
              id:e.id,
              xingming:e.xingming,
            }
          })
        }else this.$Mesg({content: res.msg,errOrSuc: 'err'})
      })
    },
    clockInNow(){
      if(testStr(this.clockInOBJ.userId)||testStr(this.clockInOBJ.xingming)){
        this.$Mesg({content:'您未选择任何员工！',errOrSuc: 'err'})
        return
      }
      this.countWokeing(this.dataList.find(e=>e.userId==this.clockInOBJ.userId))
      let form=deepCopy(this.clockInOBJ)
      if(form.wokeing==1){
        if(this.kaoqinguize.clockInRules==1){
          let diffDate=moment.duration(this.kaoqinguize.startTime.diff(this.nowDate))
          let hours=diffDate.hours(),minutes=diffDate.minutes()
          if(hours>0) {
            let i=form.clockState.indexOf('迟到')
            if(i>=0) form.clockState.splice(i,1)
            form.clockStateInfo=''
          }else if(hours<0){
            form.clockState.push('迟到')
            form.clockStateInfo=`迟到${hours?(hours*-1)+'小时':''}${minutes*-1}分钟`
          }else{
            if(minutes>=0){
              let i=form.clockState.indexOf('迟到')
              if(i>=0) form.clockState.splice(i,1)
              form.clockStateInfo=''
            }else{
              form.clockState.push('迟到')
              form.clockStateInfo=`迟到${hours?(hours*-1)+'小时':''}${minutes*-1}分钟`
            }
          }
        }else{
          let i=form.clockState.indexOf('迟到')
          if(i>=0) form.clockState.splice(i,1)
          form.clockStateInfo=''
        }
      }else{
        if(this.kaoqinguize.clockInRules==1){
          let diffDate=moment.duration(moment(this.nowDate).diff(this.kaoqinguize.endTime))
          let hours=diffDate.hours(),minutes=diffDate.minutes()
          if(hours>0) {
            let i=form.clockState.indexOf('早退')
            if(i>=0) form.clockState.splice(i,1)
            form.clockStateInfo=''
          }else if(hours<0){
            form.clockState.push('早退')
            form.clockStateInfo=`早退${hours?(hours*-1)+'小时':''}${minutes*-1}分钟`
          }else{
            if(minutes>=0){
              let i=form.clockState.indexOf('早退')
              if(i>=0) form.clockState.splice(i,1)
              form.clockStateInfo=''
            }else{
              form.clockState.push('早退')
              form.clockStateInfo=`早退${hours?(hours*-1)+'小时':''}${minutes*-1}分钟`
            }
          }
        }else{
          let diffDate=moment.duration(moment(this.shangxiaban.shangban.nowDate).add(this.kaoqinguize.workingHours,'hour').diff(this.nowDate))
          let hours=diffDate.hours(),minutes=diffDate.minutes()
          if(hours<0){
            let i=form.clockState.indexOf('早退')
            if(i>=0) form.clockState.splice(i,1)
            form.clockStateInfo=''
          }else if(hours>0){
            form.clockState.push('早退')
            form.clockStateInfo=`早退${hours?(hours)+'小时':''}${minutes}分钟`
          }else{
            if(minutes>0){
              form.clockState.push('早退')
              form.clockStateInfo=`早退${hours?(hours)+'小时':''}${minutes}分钟`
            }else{
              let i=form.clockState.indexOf('早退')
              if(i>=0) form.clockState.splice(i,1)
              form.clockStateInfo=''
            }
          }
        }
      }
      form.nowDate=this.nowDate
      axios.post('/kaoqin/clockIn',form).then((res) => {
        if(res.code===5000){
          this.getKQJLNow()
          this.$Mesg({content:'考勤成功！'})
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
  },
}
</script>
